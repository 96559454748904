import React from 'react'
import { withTheme } from 'styled-components'
import { graphql } from 'gatsby'

import { SEO } from '../components/atoms'
import Hero from '../components/molecules/Hero'
import Integrations from '../components/molecules/Integrations'
import Features from '../components/molecules/Features'
import LearnMore from '../components/molecules/LearnMore'
import Customers from '../components/molecules/Customers'
import Apps from '../components/molecules/Apps'
import FeaturesBar from '../components/molecules/FeaturesBar'
import Tophat from '../components/molecules/Tophat'
import PostTout from '../components/molecules/PostTout'

export const query = graphql`
  query homePageQuery($appVersion: String!) {
    site {
      siteMetadata {
        tagline
      }
    }
    allMdx(filter: { frontmatter: { version: { eq: $appVersion } } }) {
      edges {
        node {
          body
          frontmatter {
            type
            version
            date
            until
            category
            template
            tags
            title
            modal
            link
          }
        }
      }
    }
  }
`

export default withTheme(({
  data,
  pageContext: {
    fragments,
  } = {},
}) => (
  // TODO/HMM: investigate what relative prop here is doing in each section
  <>
    <SEO />
    <Hero
      relative
      mode="fullscreen"
      format="medium"
      data={data}
    />
    <Customers
      mode="overlay"
      format="light"
      backdrop="light"
    />
    <FeaturesBar offset format="light" />
    <Features format="light" />
    <Integrations relative format="default" />
    <PostTout format="medium" />
    <Apps relative format="light" fragments={fragments} />
    <LearnMore
      id="learn-more"
      mode="overlay"
      max="sm"
      format="light"
    />
    <Tophat format="dark" />
  </>
))
